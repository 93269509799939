<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form v-loading="loading" :model="form" label-width="8rem">
        <el-form-item label="微信支付商户号">
          <el-input v-model="form.wx_pay_mch_id"></el-input>
        </el-form-item>
        <el-form-item label="微信支付密钥">
          <el-input v-model="form.wx_pay_key"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button @click="edit" type="primary">接入检测</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="12">
      <div class="y-pointer">
        <div class="y-desc" style="font-weight: unset">支付授权目录</div>
        <div @click="copy" v-clipboard:copy="paymentScope" style="margin-top: .5rem">
          <span style="font-size: 14px;color: #606266;">
            <span>{{paymentScope}}</span>
          </span>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data(){
    return{
      form:{
        wx_pay_mch_id:"",
        wx_pay_key:"",
      },
      loading:false
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    copy(){
      this.$message.success("复制成功");
    },
    load(){
      this.loading = true;
      this.$api.account.info().then(res=>{
        this.form = res.uni_account
        this.loading = false;
      })
    },
    edit(){
      this.$api.account.uniEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  },
  computed:{
    paymentScope(){
      return location.origin + "/"
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>